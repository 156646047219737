<template>
    <div class="container">
        <ProductZoomer :base-images="images" :base-zoomer-options="zoomerOptions"/>
    </div>
</template>
<script>
    export default {
        data() {
            //console.log(json_images);
            return {
                images: json_images,
                zoomerOptions: {
                    zoomFactor: 3,
                    pane: "pane",
                    hoverDelay: 300,
                    namespace: "zoomer-bottom",
                    move_by_click: false,
                    scroll_items: 4,
                    choosed_thumb_border_color: "#dd2c00",
                    scroller_position: "bottom",
                    zoomer_pane_position: "right"
                }
            };
        }
    };


</script>

