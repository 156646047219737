<template>
    <div class="wrapper">
        <v-carousel-full
                :images="carousel_data"
                :index="index"
                :disable-scroll="false"
                @close="index = null"
        />
        <div class="v-carousel" :style="{ 'margin-left': '-' + (100 * currentSlideIndexItem) + '%' }"> <!--  -->
            <v-carousel-item
                v-for="item in carousel_data"
                :key="item.id"
                :item_data="item"
                :imageSlide="true"
                :width="slideWidth"
                @updateParent="onUpdateSalaryItem(item.id)"
            >
                <p>{{item.name}}</p>
                <p>{{item.id}}</p>
            </v-carousel-item>
        </div>
<!--        <button id="prevBtn" @click="prevSlide"><img :src="arrow_left" width="30"></button>-->
<!--        <button id="nextBtn" @click="nextSlide"><img :src="arrow_right" width="30"></button>-->
        <div class="v-scroll">
            <button id="prevBtn_thumb" @click="prevSlide"><img :src="arrow_left" width="30"></button>
            <div class="v-thumbs">
                <div class="v-carousel-thumbs" :style="{ 'margin-left': '-' + (100/4 * currentSlideIndexThumb) + '%' }"> <!---->
                    <v-carousel-thumb
                            v-for="item in carousel_data"
                            :key="item.id"
                            :item_data="item"
                            :imageSlide="true"
                            :width="slideWidth"
                            @updateParent="onUpdateSalaryThumb(item.id)"
                            :isActive="activeIndex === item.id"
                    >
                        <p>{{item.name}}</p>
                        <p>{{item.id}}</p>
                    </v-carousel-thumb>
                </div>
            </div>
            <button id="nextBtn_thumb" @click="nextSlide"><img :src="arrow_right" width="30"></button>
        </div>
    </div>
</template>
<script>
    import vCarouselItem from './GalleryCarItemComponent'
    import vCarouselThumb from './GalleryCarThumbComponent'
    import vCarouselFull from './GalleryCarFullComponent'
    import LineRight from "./svg-icons/arrow-right-s-line.svg";
    import LineLeft from "./svg-icons/arrow-left-s-line.svg";
    export default {
        name: "v-carousel",
        components: {
            vCarouselItem,
            vCarouselThumb,
            vCarouselFull,
            LineRight,
            LineLeft,
        },
        props: {
            carousel_data: {
                type: Array,
                default: () => []
            },
            interval: {
                type: Number,
                default: 0
            },
            slideWidth: {
                type: Number,
                default: 10
            },
        },
        data() {
            return {
                currentSlideIndexItem: 0,
                currentSlideIndexThumb: 0,
                index: null,
                arrow_right: LineRight,
                arrow_left: LineLeft,
                activeIndex: null,
            }
        },
        methods: {
            test() {
                console.log("test");
            },
            onUpdateSalaryItem(someData) {
                //this.currentSlideIndex = someData;
                this.index = someData - 2 //развернуть на полный экран
                // if (this.activeIndex === someData) {
                //     //this.activeIndex = null;
                // } else {
                //     this.activeIndex = someData;
                // }
                console.log("ffffff222",someData);
            },
            onUpdateSalaryThumb(someData) {
                if (this.activeIndex === someData) {
                    //this.activeIndex = null;
                } else {
                    this.activeIndex = someData;
                    this.currentSlideIndexItem = someData - 2;
                }
                //this.currentSlideIndex = someData;
                console.log("ffffff111",someData);
            },
            prevSlide() {
                if(this.currentSlideIndexThumb > 0) {
                    this.currentSlideIndexThumb--
                } else {
                    //this.currentSlideIndexThumb = this.carousel_data.length - 1
                }
            },
            nextSlide() {
                if(this.currentSlideIndexThumb < this.carousel_data.length - 1 - 3) {
                    this.currentSlideIndexThumb++
                } else {
                    //this.currentSlideIndexThumb = 0
                }
                //console.log(this.carousel_data[2].v-carousel-item );
            },
        },
        mounted() {
            if(this.interval > 0) {
                let vm = this;
                setInterval(function() {
                    vm.nextSlide()
                }, vm.interval)
            }
        }
    };
</script>
<style lang="scss">
    .wrapper {
        overflow: hidden;
        margin: 0 auto;
        *border:1px double red;
        *max-width: 500px;//300px
    }
    .v-carousel {
        width: 100%;
        height:500px;
        display: flex;
        transition: all ease .5s;
        *border:1px double blue;
        *position: relative;
    }
    @media (min-width: 768px) {
      .v-carousel {
        height: 350px;
      }
    }
    @media (min-width: 992px) {
      .v-carousel {
        height: 500px;
      }
    }
    @media (min-width: 1200px) {
      .v-carousel {
        height: 500px;
      }
    }
    .v-scroll {
        width: 100%;
        display: flex;
        *flex-wrap:wrap;
    }
    .v-scroll > .v-thumbs {
        width:100%;
        overflow: hidden;
        *height: 100%;
        *display: block;
    }
    .v-carousel-thumbs {
        width: 100%;
        height:100px;
        display: flex;
        *flex-wrap:wrap;
        transition: all ease .5s;
        *border:1px double blue;
        *position: relative;
    }
    /* button */
    #prevBtn {
        position: absolute;
        left: 0;
        top: calc(50% - 85px);
        padding: 25px 10px;
        outline: none;
        border: none;
    }
    #nextBtn {
        position: absolute;
        right: 0;
        top: calc(50% - 85px);
        padding: 25px 10px;
        outline: none;
        border: none;
    }
    #nextBtn_thumb, #prevBtn_thumb {
        background: transparent;
        *border: 0;
        outline: none;
    }
</style>