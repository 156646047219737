<template>
  <div>
    <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="10000"
        controls
        indicators
        background="#ababab"
        img-width="1024"
        img-height="180"
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
    >
      <!-- Text slides with image -->
      <b-carousel-slide>
        <template #img>
          <a href="/brand/profim">
            <img
                width="100%"
                src="/homeandoffice/img/banners/profim_banner2.jpg"
            >
          </a>
        </template>
      </b-carousel-slide>
      <b-carousel-slide
          caption="Офисные кресла из натуральных материалов"
          text="Посмотрите наш специальный раздел кресел из натуральной кожи."
      >
        <template #img>
          <a href="/catalog/kresla-rukovoditelei?material=leazer">
            <img
                width="100%"
                src="/homeandoffice/img/banners/banner_leazer.jpg"
            >
          </a>
        </template>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <a href="/brand/metta">
            <img
                width="100%"
                src="/homeandoffice/img/banners/banner_samurai.jpg"
            >
          </a>
        </template>
      </b-carousel-slide>

      <b-carousel-slide
          caption="Тест-драйв кресел для организаций"
          text="Предоставим эргономичные кресла на тест-драйв до 2 недель. *"
      >
        <template #img>
          <a href="/article/test-drive">
            <img
                width="100%"
                src="/homeandoffice/img/banners/banner-testdraiv2.jpg"
            >
          </a>
        </template>
      </b-carousel-slide>

      <b-carousel-slide
          caption="Тест-драйв кресел для организаций"
          text="Предоставим эргономичные кресла на тест-драйв до 2 недель. *"
      >
        <template #img>
          <a href="/article/test-drive">
            <img
                width="100%"
                src="/homeandoffice/img/banners/banner-testdraiv3.jpg"
            >
          </a>
        </template>
      </b-carousel-slide>
      <!-- Slides with custom text -->
<!--      -->
<!--      <b-carousel-slide img-src="https://picsum.photos/1024/180/?image=54">-->
<!--        <h1>Супер кресла!</h1>-->
<!--      </b-carousel-slide>-->
<!--      &lt;!&ndash; Slides with image only &ndash;&gt;-->
<!--      <b-carousel-slide img-src="https://picsum.photos/1024/180/?image=58"></b-carousel-slide>-->
<!--      <b-carousel-slide>-->
<!--        <template #img>-->
<!--          <a href="/product/burokrat-ch-899">-->
<!--            <img-->
<!--                width="100%"-->
<!--                src="/homeandoffice/img/1871x300.jpg"-->
<!--                alt="Фельдиперсовые кресла"-->
<!--            >-->
<!--          </a>-->
<!--        </template>-->

<!--      </b-carousel-slide>-->
<!--      &lt;!&ndash; Slides with img slot &ndash;&gt;-->
<!--      &lt;!&ndash; Note the classes .d-block and .img-fluid to prevent browser default image alignment &ndash;&gt;-->
<!--      <b-carousel-slide>-->
<!--        <template #img>-->
<!--          <img-->
<!--              class="d-block img-fluid w-100"-->
<!--              width="1024"-->
<!--              height="180"-->
<!--              src="https://picsum.photos/1024/180/?image=55"-->
<!--              alt="Фельдиперсовые кресла"-->
<!--          >-->
<!--        </template>-->
<!--      </b-carousel-slide>-->
<!--      &lt;!&ndash; Slide with blank fluid image to maintain slide aspect ratio &ndash;&gt;-->
<!--      <b-carousel-slide caption="Цвет в креслах разный" img-blank img-alt="Цветные кресла">-->
<!--        <p>-->
<!--          Очень длинное описание прок ресла.-->
<!--        </p>-->
<!--      </b-carousel-slide>-->
    </b-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slide: 0,
      sliding: null
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    }
  }
}
</script>