/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

//require('./bootstrap');

window.Vue = require('vue');


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
// Vue.component('prop-component', require('./components/PropComponent.vue').default);
// Vue.component('modal-shopping-cart-component', require('./components/HaoModalShoppingCartComponent.vue').default);
// Vue.component('modal', require('./components/ModalComponent.vue').default);
Vue.component('product-zoomer-component', require('./components/ProductZoomerComponent.vue').default);
// Vue.component('product-agile-component', require('./components/ProductAgileComponent.vue').default);
//Vue.component('product-vlightbox-component', require('./components/ProductVlightboxComponent.vue').default);
Vue.component('product-mygallery-component', require('./components/GalleryComponent.vue').default);
Vue.component('carousel-component', require('./components/CarouselComponent.vue').default);
// Vue.component('carousel-items-component', require('./components/CarouselItemsComponent.vue').default);

//import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue' //Importing
Vue.use(BootstrapVue) // Telling Vue to use this in whole application

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel)

import VueResource from 'vue-resource'
Vue.use(VueResource)

// import VueGallerySlideshow from 'vue-gallery-slideshow';
// Vue.use(VueGallerySlideshow)

import ProductZoomer from 'vue-product-zoomer'
Vue.use(ProductZoomer)

// import Slick from 'vue-slick';
// Vue.use(Slick)

// import VueAgile from 'vue-agile'
// Vue.use(VueAgile)
// import lightbox from 'vlightbox'
// Vue.use(lightbox)

// import Lightbox from '@morioh/v-lightbox'
// Vue.use(Lightbox);
// import BCarousel from 'bootstrap-vue'
// Vue.component('b-carousel', BCarousel)
// import BCarouselSlide from 'bootstrap-vue'
// Vue.component('b-carousel-slide', BCarouselSlide)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app_cart = new Vue({
//     el: '#app',
//     data () {
//         return {
//             isVisibleModalShoppingCart: false,
//         };
//     },
//     methods: {
//         showModalShoppingCart() {
//             this.isVisibleModalShoppingCart = true;
//         },
//         closeModalShoppingCart() {
//             this.isVisibleModalShoppingCart = false;
//         }
//     },
//
//
// });

var _token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
let p2 = {};
let p3 = {};
//VUE

var app = new Vue({
    el: '#app_cart',
    data: {
        details: {
            sub_total: 0,
            total: 0,
            total_quantity: 0
        },
        itemCount: 0,
        items: [],
        item: {
            id: '',
            name: '',
            price: 0.00,
            qty: 1
        },
        cartCondition: {
            name: '',
            type: '',
            target: '',
            value: '',
            attributes: {
                description: 'Value Added Tax'
            }
        },
        options: {
            target: [
                {label: 'Apply to SubTotal', key: 'subtotal'},
                {label: 'Apply to Total', key: 'total'}
            ]
        },
        select_item_on_landing: 1
    },
    mounted:function(){
        this.eeee1();
        this.eeee2();
        this.eeee3();
        this.loadItems();
        this.menuHover();
        ////this.menuHover2();
        ///this.menuBarMobile();
    },
    methods: {
        select_podkat() {
            console.log(123)
        },
        //выбор цвета
        material_and_color() {
            //console.log(event);
            var t2 = document.querySelectorAll('#material_and_color .active');
            t2.forEach(function(a) {
                a.classList.remove("active")
            });
            event.target.parentElement.parentElement.parentElement.parentElement.classList.add("active");
            var t3 = document.querySelector('#colo');
            var t4 = document.querySelector('#colo_name');
            //console.log(t3);
            t3.innerHTML = "<img src='" + event.target.currentSrc + "'>";
            t4.innerHTML = event.target.parentElement.parentElement.parentElement.querySelector('.text-body').innerText;
        },
        changeItemOnLanding(event) {
            console.log(event.target.value)
            document.getElementById("product").dataset.qty = event.target.value;
        },
        //remember
        addRememberItem: function() {
            var _this = this;
            let id_remember = document.getElementById("product_remember").dataset.id;
            this.$http.post('/lk/remember',{
                _token:_token,
                id_remember:id_remember,
            }).then(function(success) {
                console.log(id_remember, success);
            }, function(error) {
                console.log(error);
            });
        },
        removeRememberItem: function(e) {
            let id_remember = e.target.parentNode.dataset.id;
            let elem_remember = e.target.parentNode.parentNode.parentNode.parentNode;
            //console.log(_token);
            this.$http.delete('/lk/remember/'+ id_remember + '?_token=' + _token).then(function(success) {
                console.log(id_remember, success);
                elem_remember.remove();
            }, function(error) {
                console.log(error);
            });
        },
        //cart
        addItem: function() {
            //console.log($("#app_cart #product").data("price"));
            var _this = this;
            _this.item.id = document.getElementById("product").dataset.id;
            _this.item.name = document.getElementById("product").dataset.name;
            _this.item.price = document.getElementById("product").dataset.price;
            _this.item.qty = document.getElementById("product").dataset.qty;
            _this.item.attribs = {};
            // var t2 = document.querySelectorAll('[data-toggle="form-caption-2"]');
            // t2.forEach(function(a) {
            //     if(a.checked) {
            //         _this.item.attribs[a.dataset.target + ' .param_title'] = {'label': a.value, 'price': "+ 55"};
            //     }
            // });
            document.querySelectorAll('.param_select').forEach(function(a) {
                //console.log(a.querySelector('.param_title').innerHTML);
                //t3_title = a.innerHTML.getElementByClassName('.param_title');
                // t3_znach = a.querySelector('.param_znach');
                _this.item.attribs[a.querySelector('.param_title').innerHTML] = {
                    'label': a.querySelector('.param_znach').innerHTML,
                    'price': a.querySelector('.param_znach').dataset.price
                }; //t3_znach.dataset.price
            });
            var t4 = document.querySelector('#colo_name');
            if(t4 != "undefined" && t4 != null) {
                console.log(t4);
                _this.item.attribs["Материал и цвет"] = {
                    'label': t4.innerText,
                    'price': 0
                };
            }
            //console.log(_this.item.attribs);
            //_this.item.attribs = {'rrr': 1111, 'ffff': 2222};
            //$("#app_cart #product").html("<a href='/cart'>В корзине</a>");
            //$("#app_cart #product").css('background-color', '#F00');
            //$("#app_cart button").after("<a href='/lk/wishlist'>Список</a>");
            //console.log(_this.item.id);
            this.$http.post('/cart',{
                _token:_token,
                id:_this.item.id,
                name:_this.item.name,
                price:_this.item.price,
                qty:_this.item.qty,
                attribs:_this.item.attribs
            }).then(function(success) {
                _this.loadItems();
                //console.log(11111);
                // $('.main1')
                //     .clone()
                //     .css({ 'position' : 'absolute', 'z-index' : '10000', 'top' :'-40px'})
                //     .appendTo('#tyu_im')
                //     .animate({ marginLeft: 730,marginTop: -280,width: 50,height: 80}, 700,function() { $(this).remove();});
                // $("#app_cart form .linkincart").removeClass("hidden");
            }, function(error) {
                console.log(error);
            });
        },
        addCartCondition: function() {
            var _this = this;
            this.$http.post('/cart/conditions',{
                _token:_token,
                name:_this.cartCondition.name,
                type:_this.cartCondition.type,
                target:_this.cartCondition.target,
                value:_this.cartCondition.value,
            }).then(function(success) {
                _this.loadItems();
            }, function(error) {
                console.log(error);
            });
        },
        clearCartCondition: function() {
            var _this = this;
            this.$http.delete('/cart/conditions?_token=' + _token).then(function(success) {
                _this.loadItems();
            }, function(error) {
                console.log(error);
            });
        },
        removeItem: function(id) {
            var _this = this;
            this.$http.delete('/cart/'+id,{
                params: {
                    _token:_token
                }
            }).then(function(success) {
                _this.loadItems();
            }, function(error) {
                console.log(error);
            });
        },
        loadItems: function() {
            var _this = this;
            this.$http.get('/cart',{
                params: {
                    limit:10
                }
            }).then(function(success) {
                _this.items = success.body.data;
                _this.itemCount = success.body.data.length;
                _this.loadCartDetails();
            }, function(error) {
                console.log(error);
            });
        },
        loadCartDetails: function() {
            var _this = this;
            this.$http.get('/cart/details').then(function(success) {
                _this.details = success.body.data;
                //console.log(_this.details.sub_total.toFixed(0));
                //console.log(document.getElementById("tyu").dataset.cartItems);
                document.getElementById("tyu").dataset.cartItems = _this.details.total_quantity;
                //document.getElementById("#tyu").data("cart-items", _this.details.total_quantity).attr('data-cart-items', _this.details.total_quantity);
                //console.log($("#tyu").data("cart-items"));
                // col_cart = parseInt($("#tyu a span").text());
                // $("#tyu a span").text(col_cart+1);

            }, function(error) {
                console.log(error);
            });
        },
        updateItem: function(id, request) {
            var _this = this;
            // let rr =_this.items.find(function(arr) {
            //     return arr.id == id;
            // });
            //console.log(rr, rr.quantity, request.target.value);
            //console.log(event.target.value);
            this.$http.post('/cart/update/'+id,{
                _token: _token,
                qty: request.target.value,
            }).then(function(success) {
                _this.loadItems();
            }, function(error) {
                console.log(error);
            });
        },
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //ищем текущую цену и id
        selectitem: function() {
            //найти артикул который соответствует выбранным свойствам
            //--сортируем
            const p_order = {};
            Object.keys(p2).sort().forEach(function(key) {
                p_order[key] = p2[key];
            });
            //--сортируем
            let rr = Object.values(json_items).find(function(arr) {
                if(typeof(arr.props) != "undefined" && arr.props != null) {
                    //--сортируем
                    const props_order = {};
                    //console.log(arr);
                    Object.keys(arr.props).sort().forEach(function (key) {
                        props_order[key] = arr.props[key];
                    });
                    //--сортируем
                    return 1 == 1
                        && JSON.stringify(props_order) == JSON.stringify(p_order)
                        ;
                }
            });
            //если нашли то обновляем цену
            if(typeof(rr) != "undefined") {
                let t1 = document.querySelector("#article"), e = rr.id;
                t1.innerHTML = e
                document.getElementById("product").dataset.id = e;

                var t2 = document.querySelector("#price"), current_price_p2 = Number.parseFloat(rr.price);
                ///////////////////////////////
                //ДОБАВЛЯЕМ СТАТИЧНЫЕ ПАРАМЕТРЫ
                var t3 = document.querySelectorAll('[data-toggle="form-caption-3"]');
                t3.forEach(function(a) {
                    if(a.checked) {
                        //console.log(current_price);
                        var price = json_dops[a.name].prop[a.value].price;
                        current_price_p2 += price;
                        document.querySelector(a.dataset.target + ' .param_znach').dataset.price = price;
                        document.getElementById("product").dataset.price = rr.price;
                        document.getElementById("product").dataset.id = rr.id;
                    }
                });
                /////////////////////////////////
                //ОБНОВЛЯЕМ ГЛАВНУЮ ЦЕНУ
                t2.innerHTML = this.formatMoney(current_price_p2, 2, ",", " "); //"₽ " +
                console.log(rr.id);
            } else {
                console.log("нет подходящих товаров");
            }
            return current_price_p2;
        },
        ////
        formatMoney: function (amount, decimalCount = 2, decimal = ".", thousands = ",") {
            try {
                decimalCount = Math.abs(decimalCount);
                decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

                const negativeSign = amount < 0 ? "-" : "";

                let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
                let j = (i.length > 3) ? i.length % 3 : 0;

                return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
            } catch (e) {
                console.log(e)
            }
        },
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //ищем не доступные кнопки и разницу между кнопками в цене
        //найти в json_props свойства которых нет в json_items при выбранных p
        upprice: function(current_price) {
                //console.log(json_props);
                let json_item1_keys = Object.keys(json_props);
                Object.values(json_props).forEach(function (item1, i1) { //json_props
                    if(typeof(item1.prop) != "undefined" && item1.prop != null) {
                        let json_item2_keys = Object.keys(item1.prop);
                        console.log(json_item2_keys); //проблема СТРОЧНЫХ ключей
                        //console.log(item1);
                        Object.values(item1.prop).forEach(function (item2, i2) { //json_props.prop
                            //setTimeout(function(){
                                //console.log(i1, i2, p2, json_item2_keys[i2]);
                                let t1 = document.querySelector("#sizeRadio_2_" + i1 + "_" + i2); //ищем кнопку
                                t1.removeAttribute('disabled'); //выключаем кнопку
                                let p_temp = JSON.parse(JSON.stringify(p2)); //глубокое копирование выбранных кнопок
                                p_temp[json_item1_keys[i1]] = json_item2_keys[i2]; //замена в выбранных кнопках кнопки которая проходит в этом переборе
                                //console.log(i1, i2, p_temp);
                                //--сортируем
                                let p_temp_order = {};
                                Object.keys(p_temp).sort().forEach(function (key) {
                                    p_temp_order[key] = p_temp[key];
                                });
                                //--сортируем
                                let rr = Object.values(json_items).find(function (arr) {
                                    //--сортируем
                                    const props_order = {};
                                    Object.keys(arr.props).sort().forEach(function (key) {
                                        props_order[key] = arr.props[key];
                                    });
                                    //console.log(JSON.stringify(props_order), JSON.stringify(p_temp_order));
                                    //--сортируем
                                    return 1 == 1
                                        && JSON.stringify(props_order) == JSON.stringify(p_temp_order) //сравнить конфигурацию текущей итерации со всеми вариантами артикулов
                                        ;
                                });
                                if (typeof (rr) != "undefined") {
                                    let t0 = document.querySelector("#sizeRadioSpan_2_" + i1 + "_" + i2),
                                        e = rr.price - current_price;
                                    t0.innerHTML = e.toFixed(2);//"₽ " +
                                    //console.log(rr.id, rr.price);
                                } else {
                                    let t1 = document.querySelector("#sizeRadio_2_" + i1 + "_" + i2);
                                    t1.setAttribute('disabled', 'disabled');
                                    let t2 = document.querySelector("#sizeRadioSpan_2_" + i1 + "_" + i2), e = 0;
                                    t2.innerHTML = "-";
                                    console.log("артикулов с данным параметром не существует", json_item1_keys[i1], json_item2_keys[i2]);
                                }
                            //}, 200 * i2);
                        });
                    }
                });
        },
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //ищем доп свойства и их стоимость
        dopProps: function() {
            //ищем текущую цену и id
            //МЕНЯЕМ ЦЕНЫ СТАТИЧНЫХ ПАРАМЕТРОВ
            let json_p3_keys = Object.keys(p3);
            Object.values(json_dops).forEach(function(item1, i1) { //json_dops
                let get_price = json_dops[json_p3_keys[i1]].prop[p3[json_p3_keys[i1]]].price;
                Object.values(item1.prop).forEach(function(item2, i2) { //p3..prop
                    //console.log(item1, item2.price, get_price);
                    let t0 = document.querySelector("#sizeRadioSpan_3_"+i1+"_"+i2), e = item2.price - get_price;
                    t0.innerHTML = e;//"₽ " +
                });
            });
        },
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //form-caption-2
        eeee1: function() {
            var _this = this;
            let t = document.querySelectorAll('[data-toggle="form-caption-2"]');
            [].forEach.call(t, function (a, i) {
                if(a.checked && typeof(p2[a.name]) == "undefined") p2[a.name] = a.value; //выбранные свойства
                a.addEventListener("change", function () { //слушать событие CHANGE у элемента
                    //показать новое значение напротив группы значений
                    let t1 = document.querySelector(a.dataset.target + ' .param_znach'), e = a.value;
                    t1.innerHTML = e.replace(/_/, '')

                    p2[a.name] = a.value; //выбранные свойства
                    //ищем текущую цену и id
                    let current_price = _this.selectitem();
                    //ищем не доступные кнопки и разницу между кнопками в цене
                    _this.upprice(current_price);
                })
            })
            if(typeof(json_items) != "undefined" && json_items != null) {
                //ищем текущую цену и id СРАЗУ ПОСЛЕ ЗАГРУЗКИ СТРАНИЦЫ
                let current_price = _this.selectitem();
                //ищем не доступные кнопки и разницу между кнопками в цене СРАЗУ ПОСЛЕ ЗАГРУЗКИ СТРАНИЦЫ
                if(typeof(json_props) != "undefined" && json_props != null) {
                    _this.upprice(current_price);
                }
            }
        },
        //form-caption-3
        eeee2: function() {
            var _this = this;
            let t = document.querySelectorAll('[data-toggle="form-caption-3"]');
            [].forEach.call(t, function (a) {
                if(a.checked && typeof(p3[a.checked]) == "undefined") p3[a.name] = a.value; //выбранные свойства
                a.addEventListener("change", function () {
                    let t1 = document.querySelector(a.dataset.target + ' .param_znach'), e = a.value;
                    t1.innerHTML = e
                    p3[a.name] = a.value; //выбранные свойства
                    //ищем текущую цену и id
                    let current_price = _this.selectitem();
                    //ищем не доступные кнопки и разницу между кнопками в цене
                    //upprice(current_price)
                    _this.dopProps();
                })
            })
        },
        //form-caption
        eeee3: function() {
            var _this = this;
            var t = document.querySelectorAll('[data-toggle="form-caption"]');
            [].forEach.call(t, function (a) {
                a.addEventListener("change", function () {
                    var t1 = document.querySelector(a.dataset.target + ' .param_znach'), e = a.value;
                    t1.innerHTML = e
                })
            })
        },
        //ГЛАВНОЕ МЕНЮ выпадение при наведении
        menuHover: function () {
            var _this = this;
            var o = document.querySelector(".navbar-collapse");
            var d = document.querySelectorAll(".navbar-nav .dropdown, .navbar-nav .dropright");
            [].forEach.call(d, function (a, i) {
                a.addEventListener('mouseenter', function(event) {
                    _this.menuOpen(event, a, o)
                });
                a.addEventListener('mouseleave', function(event) {
                    _this.menuOpen(event, a, o)
                });
            });
        },
        menuOpen: function (event, a, o) { //
            //console.log(22222222);
            if (!o.classList.contains("show")) {
                //console.log(33333333);
                var a2 = a.querySelector('.dropdown-menu');
                //console.log(22, a2);
                "mouseenter" === event.type ?
                    (a.classList.add("show"), a2.classList.add("show"), a.classList.add("hovered"))
                    :
                    (a.classList.remove("show"), a2.classList.remove("show"), a.blur())
            }
        },
        // menuBarMobile: function () {
        //     var _this = this;
        //     let t = document.querySelectorAll('[data-toggle="collapse"][data-action]');
        //     [].forEach.call(t, function (a, i) {
        //         a.addEventListener("click", function (t) {
        //             console.log(111111);
        //             // t.stopPropagation();
        //             // var e = $(this), a = e.data("action"), o = e.data("target");
        //             // $(o).collapse(a)
        //         })
        //     })
        // },




        //ГЛАВНОЕ МЕНЮ 2
        // menuHover2: function () {
        //     var _this = this;
        //     var n = document.querySelector(".dropright"),
        //         l = document.querySelector(".dropdown-menu"),
        //         o = document.querySelector('[data-toggle="collapse"]'),
        //         e = document.querySelector(".navbar-collapse"),
        //         t = document.querySelector(".navbar .dropdown"),
        //         a = document.querySelector('.navbar .nav-link[data-toggle="smooth-scroll"]');
        //     var d = document.querySelectorAll('.navbar .dropdown-menu .dropright [data-toggle="dropdown"]');
        //     [].forEach.call(d, function (a, i) {
        //         console.log(2222222255555555);
        //         a.addEventListener('click', function(event) {
        //             _this.menuOpen2(event, a)
        //         });
        //     });
        // },
        // menuOpen2: function (event, a) { //
        //     console.log(2222222255555555);
        //
        // },

        // menu2: function () {
        //     var n = document.querySelector(".dropright"),
        //         l = document.querySelector(".dropdown-menu"),
        //         o = document.querySelector('[data-toggle="collapse"]'),
        //         e = document.querySelector(".navbar-collapse"),
        //         t = document.querySelector(".navbar .dropdown"),
        //         a = document.querySelector('.navbar .nav-link[data-toggle="smooth-scroll"]');
        //     var d = document.querySelectorAll('.navbar .dropdown-menu .dropright [data-toggle="dropdown"]');
        //     [].forEach.call(d, function (a, i) {
        //         a.addEventListener('click', function(event) {
        //             _this.menuOpen2(event, a)
        //         });
        //     });
        //     // t.on("hide.bs.dropdown", function () {
        //     //     var t = $(this), e = t.find(n).find(l), a = t.find(o);
        //     //     e.removeClass("show"), a.each(function () {
        //     //         var t = $(this);
        //     //         ($(t.attr("href")) || $(t.data("target"))).collapse("hide")
        //     //     })
        //     // }), a.on("click", function (t) {
        //     //     $(this).closest(e).collapse("hide")
        //     // })
        // },
        // menuOpen2: function (event, a) { //
        //     t.preventDefault(), t.stopPropagation();
        //     var e = $(this), a = e.closest(n).find(l), o = e.closest(l).find(l).not(a);
        //     a.toggleClass("show"), o.removeClass("show")
        // },
    }
});

// $('.navbar .dropdown-menu .dropright [data-toggle="dropdown"]').on("click", function (t) {
//     t.preventDefault(), t.stopPropagation();
//     var e = $(this), a = e.closest(n).find(l), o = e.closest(l).find(l).not(a);
//     a.toggleClass("show"), o.removeClass("show")
// }), t.on("hide.bs.dropdown", function () {
//     var t = $(this), e = t.find(n).find(l), a = t.find(o);
//     e.removeClass("show"), a.each(function () {
//         var t = $(this);
//         ($(t.attr("href")) || $(t.data("target"))).collapse("hide")
//     })
// }), a.on("click", function (t) {
//     $(this).closest(e).collapse("hide")
// })



//"use strict";
// !function () {
//     var t = $('[data-toggle="card-collapse"]'), e = $(".card-collapse");
//     t.on({
//         mouseenter: function () {
//             $(this).find(".card-collapse").collapse("show")
//         }, mouseleave: function () {
//             var t = $(this).find(".card-collapse");
//             t.hasClass("collapsing") ? setTimeout(function () {
//                 t.collapse("hide")
//             }, 350) : t.collapse("hide")
//         }
//     }), e.on({
//         "show.bs.collapse": function () {
//             var t = $(this), e = t.closest(".card-collapse-parent"), a = t.outerHeight(!0);
//             e.css({"-webkit-transform": "translateY(-" + a + "px)", transform: "translateY(-" + a + "px)"})
//         }, "hide.bs.collapse": function () {
//             $(this).closest(".card-collapse-parent").css({"-webkit-transform": "", transform: ""})
//         }
//     })
// }(), function () {
//     var t = document.querySelectorAll("[data-countdown]");
//
//     function n(t, e) {
//         var a = t - (new Date).getTime(), o = Math.floor(a / 864e5);
//         o = o < 10 ? "0" + o : o;
//         var n = Math.floor(a % 864e5 / 36e5);
//         n = n < 10 ? "0" + n : n;
//         var l = Math.floor(a % 36e5 / 6e4);
//         l = l < 10 ? "0" + l : l;
//         var i = Math.floor(a % 6e4 / 1e3);
//         i = i < 10 ? "0" + i : i, e.querySelector("[data-days]").innerHTML = o, e.querySelector("[data-hours]").innerHTML = n, e.querySelector("[data-minutes]").innerHTML = l, e.querySelector("[data-seconds]").innerHTML = i
//     }
//
//     t.length && [].forEach.call(t, function (t) {
//         var e, a, o;
//         a = (e = t).dataset.date, n(o = new Date(a).getTime(), e), setInterval(function () {
//             n(o, e)
//         }, 1e3)
//     })
// }(), (), Flickity.defaults.pageDots = !1, Flickity.defaults.prevNextButtons = !1, Flickity.defaults.imagesLoaded = !0, Flickity.defaults.initialIndex = 0, Flickity.defaults.wrapAround = !0, Flickity.defaults.cellAlign = "left", $('[data-toggle="flickity"]').on("click", function () {
//     var t = $(this), e = t.data("slide"), a = t.data("target");
//     $(a).flickity("select", e, !0, !0)
// }), function () {
//     var a, o = !1;
//     document.body.addEventListener("touchstart", function (t) {
//         t.target.closest(".flickity-slider") ? (o = !0, a = {x: t.touches[0].pageX, y: t.touches[0].pageY}) : o = !1
//     }), document.body.addEventListener("touchmove", function (t) {
//         if (o && t.cancelable) {
//             var e = {x: t.touches[0].pageX - a.x, y: t.touches[0].pageY - a.y};
//             7 < Math.abs(e.x) && t.preventDefault()
//         }
//     }, {passive: !1})
// }(), function () {
//     var t = document.querySelectorAll(".highlight");
//     "undefined" != typeof hljs && t && [].forEach.call(t, function (t) {
//         hljs.highlightBlock(t)
//     })
// }(), function () {
//     var t = document.querySelectorAll(".img-comp-input");
//     [].forEach.call(t, function (o) {
//         "input change".split(" ").forEach(function (t) {
//             o.addEventListener(t, function () {
//                 var t, e, a;
//                 e = (t = o).parentElement.querySelector(".img-comp-front"), a = t.parentElement.querySelector(".img-comp-handle"), e.style.maxWidth = t.value + "%", a.style.left = t.value + "%"
//             })
//         })
//     })
// }(), $('[data-toggle="lists"]').each(function () {
//     var t = $(this), e = t.data("options");
//     "undefined" != typeof List && new List(t.get(0), e)
// }), function () {
//     var t = document.querySelectorAll('[data-toggle="map"]');
//     t && [].forEach.call(t, function (t) {
//         var e = parseInt(t.getAttribute("data-zoom")), a = JSON.parse(t.getAttribute("data-markers")),
//             o = {lat: a[0].position[0], lng: a[0].position[1]},
//             l = new google.maps.Map(t, {center: o, zoom: e, disableDefaultUI: !0}), i = new google.maps.LatLngBounds;
//         a.forEach(function (t, e) {
//             var a = {lat: t.position[0], lng: t.position[1]}, o = new google.maps.InfoWindow({content: t.info}),
//                 n = new google.maps.Marker({position: a, map: l});
//             n.addListener("click", function () {
//                 o.open(l, n)
//             }), i.extend(a)
//         }), e || l.fitBounds(i)
//     })
// }(), (), $(function () {
//     $('[data-toggle="popover"]').popover()
// }), function () {
//     var o = $(".rating"), n = $(".rating-form");
//     $(".rating-input").on("change input", function () {
//         var t = $(this), e = t.closest(n).find(o), a = t.val();
//         e.attr("data-value", a)
//     })
// }(), $('.collapse[data-toggle="simplebar"]').on({
//     "shown.bs.collapse": function () {
//         var t = $(this), e = $(t.data("target"));
//         "undefined" != typeof SimpleBar && new SimpleBar(e.get(0))
//     }, "hidden.bs.collapse": function () {
//         var t = $(this), e = $(t.data("target"));
//         "undefined" != typeof SimpleBar && SimpleBar.instances.get(e.get(0)).unMount()
//     }
// }), function () {
//     var t = '[data-toggle="smooth-scroll"]';
//     "undefined" != typeof SmoothScroll && new SmoothScroll(t, {
//         header: ".navbar.fixed-top", offset: function (t, e) {
//             return e.dataset.offset ? e.dataset.offset : 0
//         }
//     })
// }(), $(function () {
//     $('[data-toggle="tooltip"]').tooltip()
// }), $('[data-toggle="vote"]').on("click", function (t) {
//     t.preventDefault();
//     var e = $(this), a = e.attr("data-count");
//     e.attr("data-count", ++a)
// }), (), (), ();






// var wishlist = new Vue({
//     el: '#app_wishlist',
//     data: {
//         details: {
//             sub_total: 0,
//             total: 0,
//             total_quantity: 0
//         },
//         itemCount: 0,
//         items: [],
//         item: {
//             id: '',
//             name: '',
//             price: 0.00,
//             qty: 1
//         }
//     },
//     mounted:function(){
//         this.loadItems();
//     },
//     methods: {
//         addItem: function() {
//             var _this = this;
//             _this.item.id = $("#app_wishlist #product_wishlist").data("id");
//             _this.item.name = $("#app_wishlist #product_wishlist").data("name");
//             _this.item.price = $("#app_wishlist #product_wishlist").data("price");
//             _this.item.qty = $("#app_wishlist #product_wishlist").data("qty");
//             // _this.$off('clickadditem'); //удалить подписку на пользовательское событие
//             // _this.$once('clickremoveitem', _this.item.id); //создать пользовательское событие
//             //alert("addItem");
//
//             //$("#app_wishlist button").css('background-color', '#F00');
//             $("#product_wishlist").removeClass('glyphicon-heart-empty');
//             $("#product_wishlist").addClass('glyphicon-heart');
//             $("#product_wishlist").after(" <a href='/lk/wishlist'>К сравнению</a>");
//             this.$http.post('/wishlist',{
//                 _token:_token,
//                 id:_this.item.id,
//                 name:_this.item.name,
//                 price:_this.item.price,
//                 qty:_this.item.qty
//             }).then(function(success) {
//                 _this.loadItems();
//             }, function(error) {
//                 console.log(error);
//             });
//             //_this.$once('removeItem', _this.item.id);
//         },
//         removeItem: function(id) {
//             //alert(id);
//             var _this = this;
//             $("#app_wishlist #product_wishlist").css('background-color', '#5cb85c');
//             // _this.$off('clickremoveitem'); //удалить подписку на пользовательское событие
//             // _this.$once('clickadditem'); //создать пользовательское событие
//             //alert("removeItem");
//             this.$http.delete('/wishlist/'+id,{
//                 params: {
//                     _token:_token
//                 }
//             }).then(function(success) {
//                 _this.loadItems();
//             }, function(error) {
//                 console.log(error);
//             });
//         },
//         loadItems: function() {
//             var _this = this;
//             this.$http.get('/wishlist',{
//                 params: {
//                     limit:10
//                 }
//             }).then(function(success) {
//                 _this.items = success.body.data;
//                 _this.itemCount = success.body.data.length;
//                 _this.loadCartDetails();
//             }, function(error) {
//                 console.log(error);
//             });
//         },
//         loadCartDetails: function() {
//             var _this = this;
//             this.$http.get('/wishlist/details').then(function(success) {
//                 _this.details = success.body.data;
//             }, function(error) {
//                 console.log(error);
//             });
//         }
//     }
// });