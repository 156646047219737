<template>
    <div id="app_gallery">
        <v-carousel
            :carousel_data="sliderItems"
            :slideWidth="540"
            :interval="3000000"
        />
    </div>
</template>
<script>
    import vCarousel from './GalleryCarComponent'
    export default {
        name: "app_gallery",
        components: {
            vCarousel
        },
        data() {
            //console.log(json_images.normal_size);
            return {
                sliderItems: json_images.normal_size
            };
        }
    };
</script>