<template>
    <div
            class="v-carousel-thumb"
            :class="{active: isActive}"
            @mouseenter="doSomething"

    >
<!--        @mouseleave="doSomething"-->
<!--        @click="doSomething(item_data.id)"-->
<!--        :style="{'min-width': width + 'px'}"-->
        <div class="item__image" v-if="imageSlide">
            <img :src=" item_data.thumb " alt="">
        </div>
        <div class="item__content" v-else>
            <slot></slot>
        </div>
    </div>
</template>
<script>
    export default {
        name: "v-carousel-thumb",
        props: {
            item_data: {
                type: Object,
                default: () => []
            },
            imageSlide: {
                type: Boolean,
                default: true
            },
            width: {
                type: Number,
                default: 200
            },
            isActive: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                //activeLink: undefined,
            }
        },
        methods: {
            doSomething() {
              //this.activeLink = a;
              this.$emit('updateParent')
              console.log("ggggggg999")
          }
        },
        computed: {}
    };
</script>
<style>
    .v-carousel-thumb {
        height: 100%;
        min-width: 25%;
        border:2px double white;
        *flex-shrink: 1;
        *display: inline-block;
        *white-space: normal;
        *border-radius: 5px;
    }
    .v-carousel-thumb.active {
        border:2px double red;
    }
    .v-carousel-thumb > .item__image {
        text-align: center;
        height:100%;
        width: 100%;
    }
    .v-carousel-thumb > .item__image img {
        height:100%;
        *width: 100%;
    }
</style>