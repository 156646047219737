<template>
    <div
        class="v-carousel-item"
        @click="doSomething"
    >
        <!--        @click="currentSlideIndexFull = item_data.id"-->
        <!--        :style="{'min-width': width + 'px'}"-->
        <div class="item__image" v-if="imageSlide">
            <img :src=" item_data.img " alt="">
        </div>
        <div class="item__content" v-else>
            <slot></slot>
        </div>
    </div>
</template>
<script>
    export default {
        name: "v-carousel-item",
        props: {
            item_data: {
                type: Object,
                default: () => []
            },
            imageSlide: {
                type: Boolean,
                default: true
            },
            width: {
                type: Number,
                default: 200
            },
        },
        data() {
            return {

            }
        },
        methods: {
            test() {
                console.log("test2");
            },
            doSomething() {
                this.$emit('updateParent')
                console.log("ggggggg8888")
            }
        },
        computed: {}
    };
</script>
<style>
    .v-carousel-item {
        height: 100%;
        flex-shrink: 0;
        width: 100%;
        *border:1px double green;
        *display: inline-block;
        *white-space: normal;
    }
    .v-carousel-item > .item__image {
        text-align: center;
        height:100%;
        *width: 100%;
    }
    .v-carousel-item > .item__image img {
        height:100%;
        *width: 100%;
    }
</style>